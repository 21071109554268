.produtDetail p{
   font-size: 13px !important; 
}
.produtDetail .detailImage{
  object-fit: cover;
  height: 400px;
  width: 100%;
}
.equipmentSubimg{
  height: 100px;
  width: 100px;
}
.produtDetail .slider img{
    object-fit:cover;
    height: 80px;
    width: 100px;
}
.produtDetail .slick-next,.produtDetail .slick-prev{
    height: 20px !important;
    width: 20px !important;
    font-size: 15px !important;
    background-color: white!important;
    border:none !important;
    box-shadow: 0 0 5px black;
}
.produtDetail .slick-prev{
    left: -6px !important;
    z-index: 22;
}
.produtDetail .slick-next{
    right: -10px !important;
    z-index: 22;
}
.enquiryForm form *{
    border:2px solid rgb(219, 218, 218)
}

.enquiryForm input[type="checkbox"] {
    width: 20px;        
    height: 20px;       
    background-color: #f3f3f3; 
}
.enquiryForm label{
    opacity: 0.5;
    font-size: 15px !important;
    line-height: 15px !important;
}
.plantDetail p{
    font-size: 16px !important;
    margin: 0 !important;
    padding: 0;
}
.plantDetail table tr td{
    padding-top: 30px;
    padding-bottom: 50px;
    padding-right: 20px;
}
.plantDetail table tr td:nth-child(1){
    padding-left: 30px;
}
.relatedEquipment .slick-next,.relatedEquipment .slick-prev{
    height: 30px !important;
    width: 30px !important;
   
}
.produtDetail .active::after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100px;
    height: 8px;
    border-radius: 10px 10px 0 0;
    background-color: #2E296C 
}
.slick-next,
.slick-prev {
  color: #100c5a !important;
  font-size: 16px !important;
  height: 25px !important;
  width: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  border: 1px solid #dcdcdc !important;
}

.slick-next:hover,
.slick-prev:hover {
  border: 1px solid #100c5a !important;
}

.custom-arrow:hover {
  color: #100c5a !important;
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #dcdcdc !important;
  border-radius: 50%;
}

.slick-prev {
  left: -39px !important;
}

.custom-next-arrow {
  right: -35px !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
  background-color: #100c5a !important;
}

@media (max-width:500px){
  .produtDetail .detailImage{
    height: 200px;
  }
}