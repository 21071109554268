.blogShareIcons img{
    height: 20px;
    width: 20px;
    margin: 10px 5px;
}
.blog .card{
    box-shadow: 0 0 15px -5px rgba(128, 128, 128, 0.676);
}
.bloglist img{
    object-fit: cover;
}