header {
  background: url("./images/background/homeHeader.png") no-repeat;
  background-size: 100% 100%;
  height: 670px;
  color: white;
  margin-top: 80px;
}

.header {
  height: 670px;
  background: url("./images/background/sidebar.png") no-repeat;
  background-size: 60% 125%;
  background-position: right -20px;
}

header h1 {
  font-size: 40px;
  line-height: 50px;
}

header h5 {
  font-size: 30px;
  color: #fff500;
}

header .list p {
  font-size: 16px !important;
  line-height: 17px;
}

.inputBox {
  background-color: white;
  width: 280px;
  margin: 20px 0;
  border-radius: 6px;
  height: 50px;
  line-height: 50px;
  padding-right: 15px;
  font-size: 15px;
}

.inputBox * {
  background: transparent;
  border: none;
}

.selectOptions {
  background-color: #f5f5f5;
  padding: 0 10px;
  border-radius: 6px;
}

.inputBox select {
  padding-left: 10px;
  width: 120px;
}

.inputBox input {
  width: 100%;
}

.selectOptions img {
  height: 18px;
  width: 18px;
}

.inputBox *:focus {
  outline: none;
  border-color: transparent;
}

@media (max-width: 500px) {
  header h1 {
    font-size: 26px;
  }

  .header {
    background-size: 150% 125%;
  }

  header h5 {
    font-size: 18px;
  }

  header .list p {
    font-size: 12px !important;
    line-height: 14px;
  }
  header {
    margin-top: 0px;
  }
  
}

@media (min-width: 500px) and (max-width: 768px) {
  .header {
    background-size: 110% 125%;
  }
}

mark {
  background-color: #fff500;
}

.sellAndBuy {
  background: url("./images/background/buySell.png") center;
  background-size: cover;
  position: relative;
}

.sellAndBuy .bsComponent {
  background: #eeedff;
  color: #2c1e73;
  height: 200px;
  width: 200px;
  text-align: center;
  position: relative;
  border-radius: 40px;
}

.sellAndBuy .bsComponent h1 {
  position: absolute;
  top: -50px;
  font-size: 70px;
  font-weight: 800;
}

.sellAndBuy button {
  font-size: 30px;
  color: #fff500;
}

.sellAndBuy button:hover {
  font-size: 30px;
  color: #fff500;
}

.buySellBtn {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  font-size: 28px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: inset 0 0 10px -5px rgba(0, 0, 0, 0.5);
}

.buySellBtn .bg-yellow {
  background-color: #fff500 !important;
}

@media (max-width: 500px) {
  .sellAndBuy {
    background-image: none;
  }
 .buySellBtn {
    width: 80%;
    font-size: 24px;
  }
  .buySellBtn div{
    width: 50%;
  }
  .sellAndBuy .bsComponent {
    width: 180px;
    height: 160px;
  }
}

@media (max-width: 500px) {
 .buySellBtn {
    font-size: 22px;
    top: -20px;
  }
}

.plantCard {
  border-radius: 10px;
  margin: 10px;
  border: 1px solid #635ea4;
  transition: all 0.3s;
}

.plantCard:hover .plantCardInner {
  background-color: #100c5a;
}

.plantCardInner {
  background-color: #2e296c1a;
  border-radius: 50%;
  position: relative;
  margin: 15px 7px;
  padding: 25px;
}

.plantCardInner img {
  position: absolute;
  left: -10px;
  top: -5px;
  height: 50px;
  width: 50px;
}

.plantCard p {
  font-size: 17px !important;
}

.buySellContainer {
  padding: 100px;
  padding-bottom: 120px;
}

.dottedLine {
  height: 104px;
  background-color: red;
  background: url("./images/background/dottedLine.png");
  background-size: 100% 100%;
  position: relative;
}

.dottedLine .circle {
  height: 134px;
  width: 134px;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  box-shadow: -2px -2px 20px rgb(181, 181, 181);
  transition: all 0.3s;
}

.dottedLine .circle::before {
  content: " ";
  height: 134px;
  width: 134px;
  display: block;
  position: absolute;
  border: 2px solid;
  top: -10px;
  right: -5px;
  border-radius: 50%;
  z-index: -1;
}

.circle span {
  background-color: #fff500;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: block;
  text-align: center;
  line-height: 30px;
}

.dottedLine .circle .circleText {
  position: absolute;
  top: 150px;
  text-align: center;
}

.dottedLine .circle:nth-child(2) {
  left: 30%;
  top: -5px;
}

.dottedLine .circle:nth-child(3) {
  left: 60%;
  top: -45px;
}

.dottedLine .circle:nth-child(4) {
  left: 90%;
  top: 30px;
}

.serviceCard {
  height: 154px;
  width: 134px;
  border-radius: 6px;
  margin: 7px;
  background-color: white;
  transition: all 0.3s;
}

.serviceCard:hover {
  box-shadow: 0 3px 22px -5px rgba(22, 22, 22, 0.481);
}

.serviceCard p {
  font-size: 15px !important;
  line-height: 18px;
}

.customerLove .d-flex > div {
  position: relative;
}

.customerLove .d-flex > div::after {
  content: "";
  display: block;
  height: 3px;
  width: 40%;
  background-color: #fbcb03;
  position: absolute;
  bottom: 0;
}

.customerLove .d-flex > div:nth-of-type(even)::after {
  background-color: #100c5a;
}

.customerLove .d-flex h1 {
  font-size: 60px;
}

.customerLove img {
  height: 70px;
  width: 70px;
}

@media (max-width: 576px) {
  
.customerLove img {
  height: 50px;
  width: 50px;
}
  .customerLove .d-flex h1 {
    font-size: 45px;
  }

  .serviceCard {
    width: 130px;
  }
}

.slick-next,
.slick-prev {
  color: #100c5a !important;
  font-size: 16px !important;
  height: 25px !important;
  width: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  border: 1px solid #dcdcdc !important;
}

.slick-next:hover,
.slick-prev:hover {
  border: 1px solid #100c5a !important;
}

.custom-arrow:hover {
  color: #100c5a !important;
}

.custom-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #dcdcdc !important;
  border-radius: 50%;
}

.slick-prev {
  left: -39px !important;
}

.custom-next-arrow {
  right: -35px !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
  background-color: #100c5a !important;
}

.blog .custom-prev-arrow {
  left: 90% !important;
  top: -20px;
}

.blog .custom-next-arrow {
  left: 95% !important;
  top: -20px;
}

.bestSelling {
  width: 100%;
  overflow: hidden;
  margin: 10px;
  height: 300px;
}

.bestSellingHeader {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.bestSellingHeader:hover::after {
  top: 0;
}

.bestSellingHeader::after {
  content: "";
  position: absolute;
  display: block;
  height: 200px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.434);
  top: 100%;
  transition: all 0.2s linear;
}

.bestSelling img {
  transition: all 1s linear;
}

.bestSelling:hover img {
  transform: scale(1.1);
}

.bestSellingBody {
  font-size: 12px;
  height: 100%;
}

.bestSellingBody h4 {
  font-size: 18px;
}

.bestSellingSubHeading {
  font-size: 14px !important;
}

.faq .accordion .card h2 {
  font-size: 20px;
}
@media (max-width:500px){
  .faq .accordion .card h2 {
    font-size: 16px;
  }
  .faq .accordion .card p {
    font-size: 16px !important;
  }
  
}
.faq .custom-button {
  text-decoration: none;
}

.faq .custom-button:focus,
.faq .custom-button:active {
  outline: none;
  box-shadow: none;
}

.faq .collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.faq .collapse.show {
  max-height: 500px; 
}

.blogHeader {
  height: 270px;
  overflow: hidden;
}

.blogCard {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.blogCard h5 {
  font-size: 18px;
  padding-top: 20px;
}

.blogHeader img {
  height: 270px;
  width: 100%;
  transition: all 0.2s linear;
}

.blogHeader:hover img {
  transform: scale(1.1);
}

.affilate > div {
  border: 1.5px dashed rgba(128, 128, 128, 0.647);
  border-spacing: 50px;
}
@media (max-width:500px){
  .blogHeader {
    height: 174px;
}
}


@media (max-width: 976px) {
  .buySellContainer {
    margin-top: 10%;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 50px 0;
  }

  .dottedLine {
    height: 254px;
    width: 500px;
    transform: rotate(90deg);
  }

  .dottedLine .circle {
    transform: rotate(-90deg);
  }

  .dottedLine .circle::before {
    transform: rotate(-90deg);
    border: none;
  }

  .dottedLine .circle:nth-child(2) {
    left: 15%;
    top: 58%;
  }

  .dottedLine .circle:nth-child(3) {
    left: 60%;
    top: -10%;
  }

  .dottedLine .circle:nth-child(4) {
    left: 90%;
    top: 58%;
  }

  .dottedLine .circle:nth-child(1) {
    left: -23%;
    top: -20px;
  }

  .blog .custom-prev-arrow {
    left: 80% !important;
  }
}
.floatingForm{
  right: 5%;
  position: fixed;
  bottom: 50px;
  box-shadow: 0 0 10px -5px rgb(130, 130, 130);
padding-bottom: 30px;
}
.formIcon{
  position: fixed;
  height: 60px;
  width: 60px;
  background-color: white;
  z-index: 999;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px -5px rgb(130, 130, 130);
  cursor: pointer;
}
.formIcon:hover{
  background-color: #100c5a;
}
.formIcon img{
  height:30px;
  width:30px
}