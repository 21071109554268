nav{
    font-family: "Poppins", sans-serif ;
    font-style: normal ;
    font-weight: 500;
    box-shadow: 0 5px 5px rgba(146, 144, 144, 0.719);
}
nav li{
    padding: 0 5px;
    line-height: 36px;
}
nav .nav-link{
  font-size: 16px !important;
}
.dropdown-menu{
  position: relative;
  top: 100%;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
  }
  .dropdown-submenu .dropdown-toggle{
    position: relative;
  }
.dropdown-toggle::after {
  display: none !important;
}

nav .contact  *{
  font-size: 14px !important;
  line-height: 12px;
}
nav i{
  font-size: 14px;
  padding:0 5px;
}
nav a{
  padding: 0 !important;
  padding-left: 10px !important;
}
.btn-group > .btn {
  position: absolute !important;

}
.customePopUp{
  max-width: 400px;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px -5px rgb(33, 32, 32);
  right: 3%;
  top: 10vh;
  border-radius: 4px;
}
.customePopUp p{
  font-size: 15px !important;
  line-height: 20px;
}
.customePopUp button{
  font-size: 14px !important;
}

@media  (max-width:500px){
  nav .navbar-brand img{
    width: 110px;
  }
  nav .btn{
    font-size: 14px !important;
  }

}

@media (max-width:1200px){
  
.dropdown-menu{
  position: relative !important;
  top: 100% !important;
  width: 100% !important;
  flex-wrap: wrap;
}
.innerSubdiv{
  z-index: 999 !important;
  position: relative !important;
  left: -50% !important;
  top: 30px !important;
  width: 100%;
}
}