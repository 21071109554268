@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
p{
    font-size: 18px !important;
}
a{
    text-decoration: none;
}
.bg-primary{
    background-color: #2E296C !important;
}
.bg-secondary{
    background-color: #e0e0e08f !important;
}
.bg-secondary-dark{
    background-color: rgba(224, 224, 224,.8) !important;
}
.box-shadow{
    box-shadow: 0px 0px 10px rgb(136, 136, 136)
}
.text-primary{
    color: #2E296C !important;
}
.text-white{
    color:#ffffff !important
}
.text-yellow{
    color:#FFF500 !important
}
.bg-yellow{
    background-color:#FBCB03 !important
}
.text-italic{
    font-style: italic !important;
}

.poppins{
    font-family: "Poppins", sans-serif;
    font-style: normal;
}
.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.headingEffect{
    position: relative ;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}
.headingEffect::after{
    content: " ";
    position: absolute;
    display: block ;
    height: 18px ;
    background-color: yellow ;
    width: 120%;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    z-index: -1;
}
form span{
    color: red;
}
.agentForm input,.agentForm textarea,.agentForm select{
    width: 100%;
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #D1D1D1    ;
}