.aboutcontent hr {
  width: 80%;
  opacity: 1;
  color: #635ea4;
  border-top-width: 3px;
  margin-left: 10px;
}
.aboutcontent p {
  font-size: 16px !important;
  margin: 0;
}
.benifiteHeading {
  max-width: 450px;
  position: relative;
  height: 50px;
  line-height: 50px;
}
.benifiteHeading h4 {
  font-size: 25px !important;
}
.benifiteHeading::after,
.benifiteHeading::before {
  content: "";
  display: block;
  height: 0px;
  width: 0px;
  border: 25px solid #100b54;
  position: absolute;
  bottom: 0;
}
.benifiteHeading::before {
  left: -50px;
  border-left-color: transparent;
  border-top-color: transparent;
}
.benifiteHeading::after {
  right: -50px;
  border-top-color: transparent;
  border-right-color: transparent;
}
.benefitToindivisual .row div {
  border: 2px dashed gray;
  border-left: 0;
  line-height: 25px;
}
.benefitToindivisual .row img {
  width: 58px;
}
.benefitToindivisual .row div:nth-child(1) {
  border-left: 2px dashed gray;
}

@media (max-width: 768px) {
  .benefitToindivisual .row div:nth-child(3) {
    border-left: 2px dashed gray;
  }
}
@media (max-width: 500px) {
  .benifiteHeading h4 {
    font-size: 16px !important;
    line-height: 18px;
  }
  .benifiteHeading {
    max-width: 200px;
    position: relative;
    height: 50px;
  }
  .benefitToindivisual .row div {
    border: 2px dashed gray;
  }
}

.benefiteBank {
}
.benefiteBank .border-right {
  border-right: 0.3px solid rgba(128, 128, 128, 0.682);
}
.benefiteBank .border-bottom {
  border-bottom: 0.3px solid rgba(128, 128, 128, 0.682) !important;
}
.benefiteBank .missionVisison h3 {
  color: #ff0000;
}
.benefiteBank .missionVisison P {
  font-size: 16px !important;
}
.usedEquipment{
    background-color: white;
    background-image: url('./dealbackground.png');
    background-size: 100% 100%;
}